import {
  VariantColorsResolver,
  defaultVariantColorsResolver,
  parseThemeColor,
  Button as MantineButton,
  createTheme,
  ButtonProps,
  MantineThemeOverride,
} from "@mantine/core"

export const ButtonVariantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input)
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  })

  if (parsedColor.isThemeColor) {
    if (input.variant === "filled") {
      return {
        background: "#12A53E",
        hover: "var(--mantine-color-green-7)",
        color: "var(--mantine-color-white)",
        border: "none",
      }
    }
    if (input.variant === "outline") {
      return {
        background: "var(--mantine-color-white)",
        hover: "var(--mantine-color-gray-1)",
        color: "var(--mantine-color-gray-8)",
        border: "1px solid var(--mantine-color-gray-3)",
      }
    }
  }

  if (input.variant === "gray") {
    return {
      background: "var(--mantine-color-gray-1)",
      hover: "var(--mantine-color-gray-2)",
      color: "var(--mantine-color-gray-8)",
      border: "none",
    }
  }

  if (input.variant === "destructive") {
    return {
      background: "var(--mantine-color-red-8)",
      hover: "var(--mantine-color-red-9)",
      color: "var(--mantine-color-white)",
      border: "none",
    }
  }

  return defaultResolvedColors
}

export const themeButton: MantineThemeOverride = createTheme({
  components: {
    Button: {
      defaultProps: { size: "md" },
      styles: {
        root: { paddingInline: 16 },
        label: {
          fontSize: 14,
          fontWeight: 500,
        },
      },
    },
  },
})

interface Props extends ButtonProps {
  variant?: "filled" | "outline" | "gray" | "destructive"
  onClick?: () => void
  type?: "button" | "submit" | "reset" | undefined
  form?: string
}

export const Button = ({
  variant = "filled",
  onClick,
  type,
  form,
  ...rest
}: Props) => {
  const disabledStyles = {
    filled: {
      background: "var(--mantine-color-green-6)",
      color: "var(--mantine-color-white)",
      opacity: "0.6",
    },
    outline: {
      opacity: "0.6",
      border: "1px solid rgba(0, 0, 0, .7)",
    },
    gray: {
      opacity: "0.6",
    },
    destructive: {
      opacity: "0.6",
    },
  }

  const disabledStyle = rest.disabled && disabledStyles[variant]

  return (
    <MantineButton
      variant={variant}
      type={type}
      form={form}
      {...rest}
      style={{ ...disabledStyle, ...rest.style }}
      onClick={onClick}
    >
      {rest.children}
    </MantineButton>
  )
}
